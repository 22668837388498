import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { MdClearAll } from 'react-icons/md'
import Layout from '../components/Layout'
import ContentSections from '../components/ContentSections/ContentSections'
import BannerImage from '../components/BannerImage/BannerImage'
import HomeNews from '../components/HomeNews/HomeNews'
import ScrollListener from '../components/ScrollListener'
import SEO from '../components/SEO'
import WhyChoosePNPR from '../components/WhyChoosePNPR/WhyChoosePNPR'
import { replaceURL } from '../utils'

export const HomePageTemplate = ({
  title,
  content,
  introSections,
  news,
  bannerImage,
}) => {
  return (
    <div>
      <BannerImage image={bannerImage} />
      <div className="container lg:max-w-6xl sm:py-8 home-intro-sections">
        {introSections && <ContentSections sections={introSections} />}
      </div>

      <div className="section-headers">
        <div className="flex flex-wrap news-why-choose-wrapper">
          <div className="sm:w-1/2">
            <div className="title-bar">
              <div className="inner">
                <h3 className="underlined centered-mobile">Latest News</h3>
              </div>
            </div>
            <div className="inner news-outer">
              <HomeNews news={news} />
            </div>
          </div>
          <div className="sm:w-1/2">
            <div className="title-bar">
              <div className="inner">
                <h3 className="underlined centered-mobile">Why Choose PNPR?</h3>
              </div>
            </div>
            <div className="inner">
              <WhyChoosePNPR />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const HomePage = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout removePadding="true">
      <SEO title="Home" />
      <ScrollListener />
      <HomePageTemplate
        title={page.title}
        content={page.content}
        introSections={page.acf.intro_sections}
        news={data.news.edges}
        bannerImage={data.bannerImage}
      />
    </Layout>
  )
}

export default HomePage

export const homePageQuery = graphql`
  query HomePageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        intro_sections {
          title
          content
          footnote
          button_text
          button_url
          # button {
          #   title
          #   url
          # }
          image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 600
                  maxHeight: 400
                  quality: 100
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            noHeightRestriction: localFile {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fullSize: localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          show_full_image
          make_image_zoomable
        }
      }
    }
    news: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: "home-news" } } } }
      sort: { fields: date, order: DESC }
      limit: 7
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
          content
        }
      }
    }
    bannerImage: file(relativePath: { eq: "banner7.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 600, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
