import React from 'react'
import { Link } from 'gatsby'
import './HomeNews.scss'
import { FiChevronRight } from 'react-icons/fi'

const HomeNews = ({ news }) => (
  <div className="news-wrapper">
    <div>
      {news.map(({ node: n }) => (
        <div className="news-article" key={n.id}>
          <FiChevronRight />
          <p className="date">{n.date}</p>
          <div className="content">
            <Link to={`/${n.slug}`} className="block">
              {n.title}
            </Link>
            {/* {n.excerpt && (
              <Link to={`/${n.slug}`} className="mt-3 pink block">
                Read More
              </Link>
            )} */}
          </div>
        </div>
      ))}
    </div>
    <div className="mt-8 text-center sm:text-right">
      <Link to="/category/news" className="button pink inline-block">
        Read More
      </Link>
    </div>
  </div>
)

export default HomeNews
