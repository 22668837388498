import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './BannerImage.scss'
import { MdEmail } from 'react-icons/md'

const BannerImage = ({ image }) => {
  return (
    <div className="banner-image-wrapper hidden sm:block">
      <Img className="mx-auto" alt="" fluid={image.childImageSharp.fluid} />
      <div className="text-wrapper">
        <div className="text-inner">
          <h2>
            Strategic, results-driven
            <br />
            {' '}
public relations
          </h2>
          <Link
            to="/contact"
            className="hidden button light sm:inline-block icon-button"
          >
            <MdEmail />
            Get in touch
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BannerImage
