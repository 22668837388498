import React from 'react'
import { Link } from 'gatsby'
import ContentSectionImage from './ContentSectionImage'
import { replaceURL } from '../../utils'
import './ContentSections.scss'

const getContentColumnSizes = (size, hasImage) => {
  if (!hasImage) {
    return 'w-full mx-auto'
  }
  if (size === 'small') {
    return 'md:w-2/3 lg:w-3/4'
  }
  return 'md:w-1/2 lg:w-2/3'
}

export default class ContentSections extends React.Component {
  render() {
    const {
      sections,
      extraPadding,
      size = 'large',
      width = 'w-full',
    } = this.props

    return (
      <div className={`content-sections-wrapper ${width} mx-auto`}>
        {sections.map((section, index) => (
          <div
            className={`content-section flex flex-wrap text-center md:text-left ${
              extraPadding ? 'extra-padding' : ''
            } size-${size}`}
            key={`content-section-${index}`}
          >
            {section.image && index % 2 === 0 && (
              <div
                className={`w-full ${
                  size === 'small' ? 'md:w-1/3 lg:w-1/4' : 'md:w-1/2 lg:w-1/3'
                }`}
              >
                <ContentSectionImage section={section} />
              </div>
            )}
            <div
              className={`${getContentColumnSizes(
                size,
                section.image ? true : false
              )} text-wrapper`}
            >
              <h3 className="underlined centered-mobile">{section.title}</h3>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: section.content,
                }}
              />
              {section.footnote && (
                <div
                  className="pt-4 mt-4 footnote"
                  dangerouslySetInnerHTML={{
                    __html: section.footnote,
                  }}
                />
              )}
              {section.button_text && section.button_url && (
                <Link
                  to={replaceURL(section.button_url)}
                  className="button pink inline-block"
                >
                  {section.button_text}
                </Link>
              )}
            </div>
            {section.image && index % 2 !== 0 && (
              <div
                className={`w-full ${
                  size === 'small' ? 'md:w-1/4' : 'md:w-1/2 lg:w-1/3'
                }`}
              >
                <ContentSectionImage section={section} />
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }
}
