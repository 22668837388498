import React from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'

export default class ContentSectionImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { section } = this.props
    const { isOpen } = this.state

    if (!section.image) {
      return null
    }

    const image = section.show_full_image
      ? section.image.noHeightRestriction
      : section.image.localFile

    if (section.make_image_zoomable) {
      return (
        <div className="content-section-image">
          <a
            href="#"
            onClick={() => this.setState({ isOpen: true })}
            className="cursor-pointer"
          >
            {image.childImageSharp.fluid ? (
              <Img
                className={`mx-auto opacity-100 hover:opacity-50 transition-opacity transition ease-in duration-700 ${
                  section.show_full_image ? 'full-image' : ''
                }`}
                alt=""
                fluid={image.childImageSharp.fluid}
              />
            ) : (
              <Img
                className="mx-auto opacity-100 hover:opacity-50 transition-opacity transition ease-in duration-700"
                alt=""
                fixed={image.childImageSharp.fixed}
              />
            )}
          </a>
          {isOpen && (
            <Lightbox
              mainSrc={section.image.fullSize.childImageSharp.fluid.src}
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          )}
        </div>
      )
    }
    return (
      <div>
        {image.childImageSharp.fluid ? (
          <Img
            className={`mx-auto ${section.show_full_image ? 'full-image' : ''}`}
            alt=""
            fluid={image.childImageSharp.fluid}
          />
        ) : (
          <Img className="mx-auto" alt="" fixed={image.childImageSharp.fixed} />
        )}
      </div>
    )
  }
}
