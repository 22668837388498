import React from 'react'

export default class ScrollListener extends React.Component {
  constructor(props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    const element = document.getElementsByTagName('body')[0]
    element.classList.remove('scrolled')
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(e) {
    const element = document.getElementsByTagName('body')[0]
    const newPosition = e.srcElement.scrollingElement.scrollTop

    if (newPosition > 20) {
      element.classList.add('scrolled')
    } else if (newPosition === 0) {
      element.classList.remove('scrolled')
    }
  }

  render() {
    return null
  }
}
